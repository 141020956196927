<template>
  <el-drawer title="操作日志详情" :append-to-body="true" :visible.sync="drawerVisible" min-size="48%" size="48%" direction="rtl">
    <div class="vue-box" style="padding: 20px; background-color:#ffffff;">
      <el-descriptions :colon='false' :contentStyle="rowCenter" :column="2">
        <el-descriptions-item label="事件描述:" :span="2">{{details.title}}</el-descriptions-item>
        <el-descriptions-item label="操作人员类型:">{{details.deptName}}</el-descriptions-item>
        <el-descriptions-item label="操作人员:">{{details.operName}}</el-descriptions-item>
        <el-descriptions-item label="操作IP:">{{details.operIp}}</el-descriptions-item>
        <el-descriptions-item label="位置:">{{details.operLocation}}</el-descriptions-item>
        <el-descriptions-item label="请求方式:">{{details.requestMethod}}</el-descriptions-item>
        <el-descriptions-item label="操作状态:">
          <el-tag v-if="details.status == 0" type="danger" effect="dark" size="mini">通知失败</el-tag>
          <el-tag v-if="details.status == 1" type="success" effect="dark" size="mini">通知成功</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="操作地址:">
          <el-tag type="info" effect="dark" size="mini">{{details.operUrl}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="请求时间:" :span="2">
          <el-tag type="info" effect="dark" size="mini">{{details.operTime}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="请求参数:" :span="2" />
        <el-descriptions-item :span="4">
          <el-input type="textarea" disabled="disabled" :rows="8" style="color: black" v-model="details.operParam">
          </el-input>
        </el-descriptions-item>
        <el-descriptions-item label="响应结果:" :span="2" />
        <el-descriptions-item :span="3">
          <el-input type="textarea" disabled="disabled" :rows="10" style="color: black" v-model="details.jsonResult">
          </el-input>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawerVisible: false,
      dataCount: 0,
      details: {
        id: "",
        orderId: "",
        mchId: "",
        mchName: "",
        state: null,
        notifyCount: "",
        createdAt: "",
        lastNotifyTime: "",
        spend: "",
        url: "",
        respond: "",
      },
      rowCenter: {
        // "text-align": "center",
        // "width": "50%"
        'word-break': 'break-all'
      },
    };
  },
  methods: {
    // 数据刷新
    open: function (data) {
      this.details = data;
      console.log(data);
      this.drawerVisible = true;
    },
    // 查看
    get: function () {

    },
  },
  created: function () {
  },
};
</script>